$mantine-breakpoint-xs: 36em;

.wrapper {
  position: relative;
  padding-top: 180px;
  padding-bottom: 130px;
  background-color: #1d1224;
  background-image: url(https://econcrete.nyc3.cdn.digitaloceanspaces.com/concrete-stillframe.png);
  background-size: cover;
  background-position: center;

  @media (max-width: $mantine-breakpoint-xs) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

.inner {
  position: relative;
  z-index: 1;
}

.heroLogin {
  z-index: 2;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #f9af42;
  border-color: #f9af42;
  opacity: 0.7;
  transition: opacity 200ms ease;

  &:hover {
    background-color: transparent;
    opacity: 1;
  }
}

.title {
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1px;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  color: var(--mantine-color-white);
  margin-bottom: var(--mantine-spacing-xs);
  text-align: center;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: 28px;
    text-align: left;
    padding-top: var(--mantine-spacing-lg);
  }
}

.highlight {
  // color: var(--mantine-color-primary-4);
  color: #f9af42;
}

.concreteVideoBg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  // https://www.smashingmagazine.com/2021/10/object-fit-background-size-css/
  object-fit: cover; 
}

.description {
  color: var(--mantine-color-gray-0);
  text-align: center;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: var(--mantine-font-size-md);
    text-align: left;
  }
}

.controls {
  margin-top: calc(var(--mantine-spacing-xl) * 1.5);
  display: flex;
  justify-content: center;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);

  @media (max-width: $mantine-breakpoint-xs) {
    flex-direction: column;
  }
}

.control {
  height: 42px;
  font-size: var(--mantine-font-size-md);

  &:not(:first-of-type) {
    margin-left: var(--mantine-spacing-md);
  }

  @media (max-width: $mantine-breakpoint-xs) {
    &:not(:first-of-type) {
      margin-top: var(--mantine-spacing-md);
      margin-left: 0;
    }
  }
}

.secondaryControl {
  color: var(--mantine-color-white);
  background-color: rgba(255, 255, 255, 0.4);

  @mixin hover {
    background-color: rgba(255, 255, 255, 0.45);
  }
}
