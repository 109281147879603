@-webkit-keyframes slideUp {
  0% {
    opacity: 0.01;
    transform: translateY(2.4rem);
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0.01;
    transform: translateY(2.4rem);
  }
  to {
    opacity: 1;
  }
}

.product {
  --pt-color-primary: rgb(0, 116, 212);
  --pt-color-text: #1a1a1a;

  padding: 0.5rem 1.2rem 2.5rem;
  max-width: 24rem;
  // color: var(--pt-color-text);
  color: #ffffff;
  opacity: 0.01;

  animation: slideUp 0.4s ease-in-out;
  animation-delay: calc(var(--pt-animate-in-order) * 0.2s);
  animation-fill-mode: forwards;
  // border: 2px solid #f8f8f8;
  background-color: var(--pt-white50);
  border: 1px solid var(--pt-white100);
  border-radius: 12px;

  &__content {
    &__name {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0.4rem;
      text-align: center;
    }

    &__description {
      margin-bottom: 2rem;
      font-size: 14px;
      color: var(--pt-white70);
    }

    &__priceAndButton {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-end;
    }

    &__price {
      display: flex;
      align-items: center;
      align-self: center;

      &__text {
        margin-right: 0.5rem;
        font-weight: 700;
        font-size: 32px;
      }

      &__interval {
        opacity: 0.5;
        font-size: 13px;
        line-height: 1.15;
      }
    }

    &__progress_container {
      display: flex;
      justify-content: center;
    }

    &__circular__progress {
      color: var(--pt-color-primary);
    }

    &__button {
      align-self: center;
      // background-color: var(--pt-color-primary);
      background-color: var(--pt-color-primary2);
      border-radius: var(--pt-border-radius-xs);
      margin-top: 0.8rem;
      position: relative;
      transition: filter 0.2s ease-in;
      width: min-content;
      // color: #ffffff;
      color: #1a1a1a;

      font-size: 16px;
      padding: 0.9rem 2.8rem;
      border: 1px solid var(--pt-color-primary2);

      cursor: pointer;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        opacity: 0;
        position: absolute;
        transition: opacity 0.2s ease-in;
      }

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;

        &::before {
          background-color: transparent;
          border-radius: 1rem;
          bottom: 0;
          box-shadow: 0 0 0 0.2rem var(--pt-color-primary2);
          left: 0;
          margin: -0.4rem;
          opacity: 1;
          right: 0;
          top: 0;
        }
      }

      &:active {
        transform: scale(0.98);
      }

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        margin: 0;
      }

      &[disabled] {
        // color: rgba(0, 0, 0, 0.26);
        // background-color: rgba(0, 0, 0, 0.12);
        opacity: 0.4;

        &.secondary {
          color: rgba(0, 0, 0, 0.12);
          border-color: (0, 0, 0, 0.126);
        }
      }
    }
  }

  &__feature-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 1.2rem;

    &__header {
      margin: 1.2rem 0;
      font-size: 14px;
    }

    &__items {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 1.2rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__item-icon {
        margin-right: 1.2rem;
        opacity: 0.5;
      }

      &__item-text {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}
