.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  // color: var(--mantine-color-text);
  color: #eae6db;
  font-size: var(--mantine-font-size-sm);

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  // color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  color: #eae6db;
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  position: relative;
  // &:hover {
  //   background-color: red;
  // }
  // @mixin hover {
  //   background-color: red;//light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  //   color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  // }
  &:hover {
    border-bottom: 1px solid var(--mantine-color-blue);
  }

  &:before {
    content: '';
    height: 100%;
    width: 1%;
    background-color: var(--mantine-color-blue-8);
    position: absolute;
    right: 0;
    bottom: 0;
    transition: width 100ms linear;
    opacity: 0.3;
  }

  &:hover {
    &:before {
      width: calc(100% - var(--mantine-spacing-sm));
      // right: var(--mantine-spacing-md);
    }
  }
}

.chevron {
  transition: transform 200ms ease;
}

.header {
  min-height: rem(20px);
}

.navIconBtn {
  color: #ffffff;
}
