$mantine-breakpoint-sm: 48em;

.footer {
  margin-top: 120px;
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  // background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  background-color: var(--mantine-color-dark-6);
  // border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  border-top: 1px solid  var(--mantine-color-dark-5);
}

.logo {
  max-width: 440px;

  @media (max-width: $mantine-breakpoint-sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.description {
  margin-top: 5px;

  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-xs);
    text-align: center;
  }
}

.inner {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
}

.groups {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
}

.wrapper {
  width: 160px;
}

.link {
  display: block;
  // color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
  color: var(--mantine-color-dark-1);
  font-size: var(--mantine-font-size-sm);
  padding-top: 3px;
  padding-bottom: 3px;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  // color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  color: var(--mantine-color-white);
}

.subscribe {
  margin-top: var(--mantine-spacing-xl);

  @media (min-width: $mantine-breakpoint-sm) {
    margin-top: 0;
  }
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  // border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
  border-top: 1px solid var(--mantine-color-dark-4);

  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
  }
}

.social {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-xs);
  }
}

.highlightLink {
  color: #f9af42;
}

.groupLinks {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
