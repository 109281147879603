$mantine-breakpoint-xs: 36em;

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-md);
  text-align: center;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
    text-align: left;
  }
}
