
.darkRoot {
  padding-top: 80px;
  padding-bottom: 80px;
}

.inner {
  position: relative;
}

.image {
  position: absolute;
  inset: 0;
  opacity: 0.75;
  color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;

  @media (max-width: '48em') {
    padding-top: rem(120px);
  }
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  color: var(--mantine-color-gray-0);

  @media (max-width: '48em') {
    font-size: 32px;
  }
}

.description {
  max-width: 540px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  color: var(--mantine-color-gray-3);
}
