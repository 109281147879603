.mix-design-card {
  // outline: 2px solid red;
  // position: relative;
  // padding-bottom: 3rem;

  // &__actions {
  //   position: absolute;
  //   width: 100%;
  //   left: 0;
  //   padding: 0 1rem;
  //   justify-content: space-around;
  // }

  &__delete {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0.2;
    transition: opacity 125ms linear;
    z-index: 10;
  }

  &__add-more {
    cursor: pointer;
    height: 8rem;
  }

  &:hover {
    .mix-design-card__delete {
      opacity: 1;
    }
  }
}

.aggregate-field__input input,
.dynamic-field__input input {
  cursor: pointer;
}

.aggregate-field__input.is-editing input,
.dynamic-field__input.is-editing input {
  cursor: text;
}

.mix-designs-title {
  position: relative;
}

.mix-designs-title:before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #ece;
  top: 50%;
  position: absolute;
}

.mix-designs-title > span {
  background-color: #fff;
  position: relative;
  padding-right: 1rem;
}
