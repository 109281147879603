$mantine-breakpoint-xs: 36em;
$mantine-breakpoint-sm: 48em;
$mantine-breakpoint-md: 62em;

.wrapper {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  overflow: hidden;
  // padding-bottom: calc(var(--mantine-spacing-xl) * 4);
}

.zigzagFeature {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -2rem;

  @media (min-width: $mantine-breakpoint-sm) {
    flex-direction: row;
    margin-bottom: 7rem;

    &.zigzagFeatureRTL {
      flex-direction: row-reverse;
    }
  }
}

.zigzagFeatureImageWrapper {
  height: 320px;

  img {
    object-fit: contain;
  }

  @media (min-width: $mantine-breakpoint-sm) {
    height: auto;
  }
}

.zigzagFeatureTextWrapper {
  position: relative;
  top: -100px;
  background-color: rgba(238, 238, 238, 0.75);
  padding: 1rem;

  @media (min-width: $mantine-breakpoint-sm) {
    top: 0;
  }
}

.zigzagFeatureSubtext {
  color: #07919b;
  font-style: italic;
}

.zigzagFeatureDescription {
  color: #363636;
}

.zigzagFeatureList {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
  }
}
