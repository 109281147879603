.container {
  background-color: var(--ly-container);
  margin: 0;
}

.createCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.createCardBtn {
  cursor: pointer;
}

.modalContent {
  min-height: 500px;
}

.formCardEven {
  background-color: #f4f4f4;
}

.manageCard {
  overflow: inherit;
}

.mangageMenuBtn {
  align-self: flex-start;
}

.inputDisabled {
  &:has(input:disabled) {
    > div {
      display: flex !important;
    }

    > input {
      color: var(--mantine-color-dark-9);
    }
  }
}

.spec-0,
.spec-2,
.spec-4 {
  background-color: #f4f4f4;
}
