.product-table-container {
  --pt-color-dark: #1d1224;
  --pt-color-primary2: #f9af42;

  --pt-gray-50: hsla(0, 0%, 10%, 0.1);
  --pt-white50: rgba(255,255,255,0.1);
  --pt-white70: rgba(255,255,255,0.7);
  --pt-white100: rgba(255,255,255,0.2);
  --pt-border-radius: 12px;
  --pt-border-radius-sm: 8px;
  --pt-border-radius-xs: 6px;
  --pt-border-radius-rounded: 50px;

  background-color: var(--pt-color-dark);
}

.product-table {
  display: grid;
  grid-gap: 0.8rem;
  // grid-template-columns: repeat(auto-fill, minmax('31.2rem', '31.2rem'));
  justify-content: center;
  width: 100%;
}

.interval-picker {
  --pt-color-primary: #007d4d;
  --pt-text-color: #1a1a1a;
  --pt-text-color-selected: #ffffff;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: flex-start;
    padding: 0.2rem;
    border-radius: var(--pt-border-radius-sm);

    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: var(--pt-white50);
    // border: 1px solid rgba(0, 0, 0, 0.1);
    border: 1px solid var(--pt-white100);
  }

  &__tab {
    z-index: 2;
    width: 8rem;

    &:first-of-type {
      margin-left: 0;
    }

    &__item {
      display: flex;
      height: auto;
      width: 100%;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      appearance: none !important;
      background-color: transparent;
      border: 0;
      border-radius: 0.6rem;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      position: relative;
      transition: color 0.15s ease-in, box-shadow 0.1s ease-in, border 0.08s ease-in;

      &:focus-visible,
      &:focus,
      &:active {
        outline: none;
        box-shadow: 0 0 6px 0 var(--pt-color-primary2);
      }

      &__label {
        cursor: pointer;
        font-weight: 400;
        // color: var(--pt-text-color);
        color: #ffffff;
        font-size: 14px;
        line-height: 2rem;

        flex: 0 1 100%;
        min-width: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        opacity: 0.7;
        will-change: opacity;
        transition: opacity 0.2s ease;
      }

      &--is-selected &__label {
        // color: var(--pt-text-color-selected);
        color: #1a1a1a;
        opacity: 1;
      }

      &:hover &__label {
        opacity: 1;
      }
    }
  }

  &__glider {
    display: flex;
    height: 3rem;
    width: 8rem;
    border-radius: var(--pt-border-radius-xs);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
    box-sizing: border-box;
    border: 1px solid hsla(0, 5, 10%, 0.1);
    // background-color: rgb(0, 116, 212);
    background-color: var(--pt-color-primary2);
    transform: translateX(calc(var(--glider-offset) - 2px));
    transition: 0.25s ease-out;
    position: absolute;
    z-index: 1;
  }
}
