.container {
  background-color: var(--ly-container);
  margin: 0;
}

.createCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.createCardBtn {
  cursor: pointer;
}
