$mantine-breakpoint-xs: 36em;

.wrapper {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-md);
  text-align: center;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
    text-align: left;
  }
}

.description {
  text-align: center;

  @media (max-width: $mantine-breakpoint-xs) {
    text-align: left;
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featureDescription {
  text-align: justify;
  color: #363636;
}
